<template>
    <v-card width="300" height="250" class="custom-border">
        <v-img
            alt="certificate"
            :src="require('@/assets/images/certificate-default.png')"
            :lazy-src="require('@/assets/images/certificate-default.png')"
            contain
            height="250"
            class="align-end"
            gradient="to top, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        >
            <v-sheet class="px-3 pt-1 fw600 text-center">
                {{
                    !certificate.course
                        ? "Untitled"
                        : certificate.course.title.length > 20
                          ? `${certificate.course.title.slice(0, 17)}...`
                          : certificate.course.title
                }}
                <div
                    class="py-0 secondary-2--text f11 text-uppercase"
                    v-if="certificate.course?.user_certificate"
                >
                    ISSUED
                    {{
                        $dateFormat.mmDDyy(
                            certificate.course?.user_certificate?.created_at,
                        )
                    }}
                </div>
            </v-sheet>
            <v-sheet>
                <div class="text-center text-sm">
                    Status: {{ courseStatus ? "Completed" : "Not Completed" }}
                </div>
                <v-row no-gutters>
                    <v-col cols="6">
                        <v-btn
                            color="l-primary"
                            plain
                            class="f10"
                            block
                            :disabled="!certificate.course?.user_certificate"
                            @click="
                                view(certificate.course?.user_certificate?.url)
                            "
                        >
                            VIEW
                        </v-btn>
                    </v-col>
                    <v-col cols="6" v-if="certificate.course?.user_certificate">
                        <v-btn
                            :loading="loading"
                            color="primary"
                            plain
                            class="f10"
                            :disabled="!certificate.course?.user_certificate"
                            @click="
                                download(
                                    certificate.course?.user_certificate?.url,
                                )
                            "
                        >
                            DOWNLOAD
                        </v-btn>
                    </v-col>
                    <v-col cols="6" v-else>
                        <v-btn
                            :loading="loading"
                            color="primary"
                            plain
                            class="f10"
                            :disabled="!courseStatus"
                            @click="generate"
                        >
                            Generate Certificate
                        </v-btn>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-img>
        <v-dialog
            v-model="dialog"
            persistent
            :width="$vuetify.breakpoint.mobile ? '80vw' : '40vw'"
        >
            <v-card>
                <v-btn icon @click="close" class="ml-auto float-right" dense>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <div class="container-frame">
                    <iframe class="responsive-iframe" :src="`${selected}`">
                    </iframe>
                </div>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<style scoped>
.container-frame {
    position: relative;
    overflow: hidden;
    width: 100%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
    height: 500px;
}

@media only screen and (max-width: 768px) {
    .responsive-iframe {
        height: 300px;
        width: 100%;
    }
}
</style>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    props: ["certificate"],
    data: () => ({
        dialog: false,
        selected: null,
        loading: false,
    }),
    computed: {
        courseStatus: function () {
            const totalTopics =
                this.certificate.course?.topics.length +
                this.certificate.course?.sub_topics.length +
                this.certificate.course?.assessments.length;
            const totalDone =
                this.certificate.course?.total_done_assessments +
                this.certificate.course?.total_done_sub_topics +
                this.certificate.course?.total_done_topics;

            if (totalDone >= totalTopics) return true;

            return false;
        },
    },
    methods: {
        ...mapActions("usr", [
            "downloadCourseCertificateAction",
            "generateCertificateAction",
        ]),

        view(url) {
            this.dialog = true;
            this.selected = url;
        },
        close() {
            this.dialog = false;
        },
        download(url) {
            this.downloadCourseCertificateAction({ url: url })
                .then((res) =>
                    //console.log(res)
                    window.open(res),
                )
                .catch((errs) =>
                    this.alertMutation({
                        show: true,
                        text: "Unexpected error encountered while downloading.",
                        type: "error",
                    }),
                );
        },

        generate() {
            this.loading = true;
            this.generateCertificateAction({
                course_id: this.certificate.course.id,
            })
                .then((res) => {
                    this.loading = false;
                    alert(
                        "The certificate has been emailed to you. Please refresh the page to view it.",
                    );
                    location.reload();
                })
                .catch((res) => {
                    console.log(res.code);
                });
        },
    },
};
</script>
